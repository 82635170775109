import {graphql} from "gatsby";
import {MDXRenderer} from "gatsby-plugin-mdx";
import React from "react";
import {Helmet} from "react-helmet";
// @ts-ignore
import Prism from "prism-react-renderer/prism";

// @ts-ignore
(typeof global !== "undefined" ? global : window).Prism = Prism;

require("prismjs/components/prism-rust");
require("prismjs/components/prism-php");
require("prismjs/components/prism-batch");

export default function PostPage({data}: any) {
    const {
        body,
        frontmatter: { title, date, description, embeddedImagesLocal },
        timeToRead,
    } = data.mdx;
    return (
        <>
            <Helmet>
                <title>{title} – Karl Erik Hofseth</title>
                <meta name="description" content={description}/>
            </Helmet>
            <h1>{title}</h1>
            <p>{date}</p>
            <p><i>{timeToRead} {timeToRead > 1 ? "minutes" : "minute"} read</i></p>
            <MDXRenderer
                localImages={embeddedImagesLocal}
            >{body}</MDXRenderer>
        </>
    );
}

export const query = graphql`
  query POST_BY_SLUG($slug: String) {
    mdx(slug: { eq: $slug }) {
      id
      body
      timeToRead
      frontmatter {
        date
        title
        description
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
